import React from 'react';
import { IconColorsWrapper } from './IconColorsWrapper';

const UploadIcon = ({ width, height, ...props }) => {
  return (
    <IconColorsWrapper>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 18 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M6.375 4.44699H8.10703V12.3759C8.10703 12.479 8.19141 12.5634 8.29453 12.5634H9.70078C9.80391 12.5634 9.88828 12.479 9.88828 12.3759V4.44699H11.625C11.782 4.44699 11.8687 4.26652 11.7727 4.14465L9.14766 0.821209C9.13012 0.798798 9.10771 0.780674 9.08212 0.76821C9.05654 0.755745 9.02846 0.749268 9 0.749268C8.97154 0.749268 8.94346 0.755745 8.91788 0.76821C8.89229 0.780674 8.86988 0.798798 8.85234 0.821209L6.22734 4.1423C6.13125 4.26652 6.21797 4.44699 6.375 4.44699ZM17.5781 11.6728H16.1719C16.0688 11.6728 15.9844 11.7571 15.9844 11.8603V15.4696H2.01562V11.8603C2.01562 11.7571 1.93125 11.6728 1.82812 11.6728H0.421875C0.31875 11.6728 0.234375 11.7571 0.234375 11.8603V16.5009C0.234375 16.9157 0.569531 17.2509 0.984375 17.2509H17.0156C17.4305 17.2509 17.7656 16.9157 17.7656 16.5009V11.8603C17.7656 11.7571 17.6812 11.6728 17.5781 11.6728Z"
          fill="currentColor"
        />
      </svg>
    </IconColorsWrapper>
  );
};

export { UploadIcon };
