import React from 'react';
import { IconColorsWrapper } from '.';

const AZIcon = props => {
  return (
    <IconColorsWrapper>
      <svg
        width={props.width || '22'}
        height={props.height || '22'}
        viewBox="0 0 24 24"
      >
        <path
          fill={props?.fill || 'currentColor'}
          d="M19.707 14.707A1 1 0 0 0 19 13h-7v2h4.586l-4.293 4.293A1 1 0 0 0 13 21h7v-2h-4.586l4.293-4.293zM7 3.99H5v12H2l4 4l4-4H7zM17 3h-2c-.417 0-.79.259-.937.649l-2.75 7.333h2.137L14.193 9h3.613l.743 1.981h2.137l-2.75-7.333A1 1 0 0 0 17 3zm-2.057 4l.75-2h.613l.75 2h-2.113z"
        />
      </svg>
    </IconColorsWrapper>
  );
};

export { AZIcon };
