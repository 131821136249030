import React from 'react';
import { IconColorsWrapper } from './IconColorsWrapper';

const DownloadIcon = ({ width = 16, height = 16 }) => {
  return (
    <IconColorsWrapper>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 18 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.85234 12.4922C8.86988 12.5146 8.89229 12.5327 8.91788 12.5452C8.94346 12.5577 8.97154 12.5641 9 12.5641C9.02846 12.5641 9.05654 12.5577 9.08212 12.5452C9.10771 12.5327 9.13012 12.5146 9.14766 12.4922L11.7727 9.17109C11.8687 9.04922 11.782 8.86875 11.625 8.86875H9.88828V0.9375C9.88828 0.834375 9.80391 0.75 9.70078 0.75H8.29453C8.19141 0.75 8.10703 0.834375 8.10703 0.9375V8.86641H6.375C6.21797 8.86641 6.13125 9.04688 6.22734 9.16875L8.85234 12.4922ZM17.5781 11.6719H16.1719C16.0688 11.6719 15.9844 11.7563 15.9844 11.8594V15.4688H2.01562V11.8594C2.01562 11.7563 1.93125 11.6719 1.82812 11.6719H0.421875C0.31875 11.6719 0.234375 11.7563 0.234375 11.8594V16.5C0.234375 16.9148 0.569531 17.25 0.984375 17.25H17.0156C17.4305 17.25 17.7656 16.9148 17.7656 16.5V11.8594C17.7656 11.7563 17.6812 11.6719 17.5781 11.6719Z"
          fill="currentColor"
        />
      </svg>
    </IconColorsWrapper>
  );
};

export { DownloadIcon };
